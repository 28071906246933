document.addEventListener('turbolinks:load', () => {
    $('.item').removeClass('active');
    switch (window.location.pathname) {
        case '/':
            $('#homeItem').addClass('active');
            break;
        case '/license_plates':
            $('#plateListItem').addClass('active');
            break;
        case '/registered_license_plates':
            $('#registeredPlateListItem').addClass('active');
            break;
        case '/notifications':
            $('#notificationListItem').addClass('active');
            break;
        case '/business_intelligences':
            $('#businessIntelligenceListItem').addClass('active');
            break;
        case '/face_attributes':
            $('#faceAttributeBiItem').addClass('active');
            break;
        case '/users/sign_in':
            $('#signInItem').addClass('active');
            break;
        default:
            break;
    }
});

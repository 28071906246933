import consumer from "./consumer"

document.addEventListener("turbolinks:load", () => {
  const data = document.getElementById("data")
  if (data != null) {
    const user_id = data.getAttribute("data-user-id")

    consumer.subscriptions.create({ channel: "LicensePlateChannel", user_id: user_id }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var parent = document.getElementById('license_plate_list_' + String(user_id));
        if (parent != null) {
          var reference = document.getElementById('license_plate_list_' + String(user_id)).firstChild;
          var newEle = document.createElement('tr');

          newEle.innerHTML =
            `<td>${data.camera_map[data.license_plate.camera_id]}</td>
              <td>
                <div class="modal-open" data-image-url=${data.license_plate.frame_image_path}>
                  <img class="license_plate_frame_image" src="${data.license_plate.frame_image_path}">
                </div>
              </td>
              <td>${data.tag_list}</td>
              <td>${data.license_plate.kanji}</td>
              <td>${data.license_plate.small}</td>
              <td>${data.license_plate.hiragana}</td>
              <td>${data.license_plate.big}</td>
              <td>${data.created_at}</td>
              <td>
                <form class="button_to" method="get" action="/license_plates/${data.license_plate.id}/edit"><input class="ui inverted green button" type="submit" value="編集"></form>
                <form class="button_to" method="post" action="/license_plates/${data.license_plate.id}"><input type="hidden" name="_method" value="delete" autocomplete="off"><input class="ui inverted red button" type="submit" value="削除"><input type="hidden" name="authenticity_token" autocomplete="off"></form>
              </td> `;
          var rtn = parent.insertBefore(newEle, reference);
          return $('#license_plate_list_' + String(user_id));
        }
      },
    });
  }
})

import consumer from "./consumer"

document.addEventListener("turbolinks:load", () => {
  const data = document.getElementById("data")
  if (data != null) {
    const admin_id = data.getAttribute("data-user-id")

    consumer.subscriptions.create({ channel: "RecyclingChannel", admin_id: admin_id }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Ref) https://stackoverflow.com/questions/63770241/can-i-use-actioncable-to-refresh-the-page
        // Called when there's incoming data on the websocket for this channel
        var parent = document.getElementById('recycling_list_' + String(admin_id));
        if (parent != null && data.admin_id == admin_id) {
          // return location.reload();
          // or...
          return Turbolinks.visit(location.toString());
        }
      },
    });
  }
})

import Chart from 'chart.js/auto'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('count-pedestrain-attributes') != null) {
    const context = document.getElementById('count-pedestrain-attributes').getContext('2d');
    console.log(context.canvas.dataset);
    const countPedestrainAttributesGraph = new Chart(context, {
      type: 'bar',
      data: {
        labels: JSON.parse(context.canvas.dataset.labels),
        datasets: [
          {
            label: JSON.parse(context.canvas.dataset.pedestrainAttributesLabel1),
            backgroundColor: context.canvas.dataset.pedestrainAttributesColor1,
            hoverBackgroundColor: "rgb(255, 218, 185)",
            data: JSON.parse(context.canvas.dataset.pedestrainAttributesData1),
          },
          {
            label: JSON.parse(context.canvas.dataset.pedestrainAttributesLabel2),
          //  backgroundColor: context.canvas.dataset.pedestrainAttributesColor2,
            backgroundColor: context.canvas.dataset.pedestrainAttributesColor3,
            hoverBackgroundColor: "rgb(255, 218, 185)",
            data: JSON.parse(context.canvas.dataset.pedestrainAttributesData2),
          },
          //{
          //  label: JSON.parse(context.canvas.dataset.pedestrainAttributesLabel3),
          //  backgroundColor: context.canvas.dataset.pedestrainAttributesColor3,
          //  hoverBackgroundColor: "rgb(255, 218, 185)",
          //  data: JSON.parse(context.canvas.dataset.pedestrainAttributesData3),
          //},
          //{
          //  label: JSON.parse(context.canvas.dataset.pedestrainAttributesLabel4),
          //  backgroundColor: context.canvas.dataset.pedestrainAttributesColor4,
          //  hoverBackgroundColor: "rgb(255, 218, 185)",
          //  data: JSON.parse(context.canvas.dataset.pedestrainAttributesData4),
          //},
        ]
      },
      options: {
        scales: {
          y: {
            stacked: true,
            beginAtZero: true
          },
          x: {
            stacked: true,
          }
        }
      },
    });
  }
})
